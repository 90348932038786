import PropTypes from 'prop-types';
import React from 'react';

import ProfessionalGapScheme from 'professional/models/professional_gap_scheme.js';

export default class ParticipationTable extends React.Component {
    static propTypes = {
        gapSchemes: PropTypes.arrayOf(
            PropTypes.instanceOf(ProfessionalGapScheme),
        ).isRequired,
        isSpecialist: PropTypes.bool.isRequired,
    };

    render() {
        return (
            <>
                {this.props.gapSchemes.map((gapScheme) => {
                    return (
                        <p key={gapScheme.id}>
                            <h2>{gapScheme.gapScheme.name}</h2>
                            <small
                                dangerouslySetInnerHTML={{
                                    __html: gapScheme.notes,
                                }}
                            ></small>
                        </p>
                    );
                })}
            </>
        );
    }
}
