export default class Procedure {
    static loadAll(procedures) {
        return procedures.map((procedure) => {
            const proc = new Procedure();
            proc.load(procedure);
            return proc;
        });
    }

    constructor() {
        this.id = null;
        this.name = '';
        this.description = '';
        this.url = null;
        this.admissionsStartDate = null;
        this.admissionsEndDate = null;
        this.healthFundIdentifier = '';
        this.mbsItems = [];
        this.bands = [];
    }

    load(data) {
        this.id = data.id;
        this.name = data.name;
        this.description = data.description;
        this.url = data.url;
        if (data.admissions_start_date) {
            this.admissionsStartDate = new Date(data.admissions_start_date);
        } else {
            this.admissionsStartDate = null;
        }
        if (data.admissions_end_date) {
            this.admissionsEndDate = new Date(data.admissions_end_date);
        } else {
            this.admissionsEndDate = null;
        }
        this.healthFundIdentifier = data.health_fund_identifier;
        this.mbsItems = data.mbs_items?.map((item) => ({
            id: item.id,
            number: item.number,
        }));
        this.bands = data.bands?.map((band) => ({
            id: band.id,
            name: band.name,
            admissions: band.admissions,
        }));
    }
}
