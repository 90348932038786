// Various methods that assist with templating

export function renderTemplate(input, data) {
    for (const key of Object.keys(data)) {
        const re = new RegExp(`{{\\s?${key}\\s?}}`, 'g');
        input = input.replace(re, escapeHtml(data[key]));
    }
    return input;
}

export function escapeHtml(input) {
    const d = document.createElement('div');
    d.textContent = input;
    return d.innerHTML;
}
