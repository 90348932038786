import React from 'react';
import PropTypes from 'prop-types';

import autobind from 'common/decorators/autobind.js';
import Icon from 'core/components/Icon.js';
import Tooltip from 'core/components/Tooltip.js';

export default class Ad extends React.Component {
    static propTypes = {
        adUnit: PropTypes.string.isRequired,
        customClass: PropTypes.string,
        networkCode: PropTypes.string.isRequired,
        slotNumber: PropTypes.number.isRequired,
        targeting: PropTypes.shape({
            bannerRegions: PropTypes.array,
            dev: PropTypes.bool,
            healthFund: PropTypes.string,
            locality: PropTypes.array,
            loggedInPro: PropTypes.bool,
            loggedInSpecialty: PropTypes.array,
            loggedInUserType: PropTypes.string,
            section: PropTypes.string.isRequired,
            specialty: PropTypes.array,
        }).isRequired,
    };

    constructor(props) {
        super(props);
        const {
            adUnit,
            slotNumber,
            targeting: {section},
        } = props;
        this.adSlotId = `ad-${section}-${adUnit + slotNumber}`;
        this.state = {
            adNode: null,
        };
        this.slotRef = React.createRef();
    }

    componentDidMount() {
        this.createGoogleTag();
        this.setGoogleTagCmd();
    }

    createGoogleTag() {
        if (!window.googletag) {
            window.googletag = {};
            window.googletag.cmd = [];
            const scriptElWithNonce = document.querySelector('[nonce]');
            const gads = document.createElement('script');
            gads.src = '//www.googletagservices.com/tag/js/gpt.js';
            gads.setAttribute(
                'nonce',
                scriptElWithNonce.nonce ||
                    scriptElWithNonce.getAttribute('nonce'),
            );
            const node = document.getElementsByTagName('script')[0];
            node.parentNode.insertBefore(gads, node);
        }
    }

    setSize(adUnit) {
        return {
            'header': {
                mobile: [300, 50],
                desktop: [728, 90],
            },
            'sidebar': {
                mobile: [300, 250],
                desktop: [300, 250],
            },
        }[adUnit];
    }

    setGoogleTagCmd() {
        const {targeting, networkCode, adUnit, slotNumber} = this.props;
        const size = this.setSize(adUnit);
        const adSlotId = this.adSlotId;
        this.googletag = window.googletag;
        this.googletag.cmd.push(() => {
            const mapping = this.googletag
                .sizeMapping()
                .addSize([0, 0], size.mobile)
                .addSize([1050, 200], size.desktop)
                .build();
            const slot = this.googletag
                .defineSlot(
                    `/${networkCode}/${adUnit}`,
                    [size.mobile, size.desktop],
                    adSlotId,
                )
                .defineSizeMapping(mapping)
                .addService(this.googletag.pubads().setForceSafeFrame(true))
                .setCollapseEmptyDiv(true, true);
            Object.keys(targeting).map((key) =>
                slot.setTargeting(key, targeting[key]),
            );
            slot.setTargeting('num', slotNumber);
            this.googletag
                .pubads()
                .addEventListener('slotRenderEnded', this.setTooltipRef);
            this.googletag.enableServices();
            this.googletag.display(adSlotId);
        });
    }

    @autobind
    setTooltipRef() {
        const adNode = this.slotRef.current.children[0];
        this.setState({adNode});
    }

    tooltipContent() {
        return (
            <>
                <p>{'This content is independent of the website.'}</p>
                <p>
                    {/* eslint-disable-next-line max-len */}
                    <a href="https://www.healthsharedigital.com/solutions/health-advertising/">
                        {'Find out more'}
                    </a>
                    {' about online sponsored content on HealthShare.'}
                </p>
            </>
        );
    }

    render() {
        const {adUnit, customClass} = this.props;
        const {adNode} = this.state;
        return (
            <div className={`slot ${customClass}`.trim()}>
                <div
                    className={`slot-${adUnit}`}
                    id={this.adSlotId}
                    ref={this.slotRef}
                ></div>
                {adNode?.hasChildNodes() && (
                    <Tooltip
                        content={this.tooltipContent()}
                        defaultPosition="bottom"
                        timeOut={500}
                    >
                        <small>
                            {'Sponsored'}
                            <Icon name="info" />
                        </small>
                    </Tooltip>
                )}
            </div>
        );
    }
}
