import Procedure from 'health_funds/models/procedure.js';
import ProfessionalGapScheme from 'professional/models/professional_gap_scheme.js';

export default class ProfessionalHealthFund {
    static loadAll(professionalHealthFunds) {
        return professionalHealthFunds.map((professionalHealthFund) => {
            const hf = new ProfessionalHealthFund();
            hf.load(professionalHealthFund);
            return hf;
        });
    }

    constructor() {
        this.id = null;
        this.feeComments = '';
        this.professionalGapSchemes = [];
        this.procedures = [];
    }

    load(data) {
        this.id = data.id;
        this.feeComments = data.fee_comments;
        this.professionalGapSchemes = ProfessionalGapScheme.loadAll(
            data.professional_gap_schemes,
        );
        this.procedures = Procedure.loadAll(data.procedures);
    }
}
