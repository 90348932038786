import GapScheme from 'base/models/gap_scheme.js';

export default class ProfessionalGapScheme {
    static loadAll(professionalGapSchemes) {
        return professionalGapSchemes.map((professionalGapScheme) => {
            const gs = new ProfessionalGapScheme();
            gs.load(professionalGapScheme);
            return gs;
        });
    }

    constructor() {
        this.id = null;
        this.notes = '';
        this.participationFrequency = null;
        this.participationTimePeriod = '';
        this.participationProceduresPerformed = null;
        this.notParticipatingTitle = '';
        this.gapScheme = null;
    }

    load(data) {
        this.id = data.id;
        this.notes = data.notes;
        this.participationFrequency = data.participation_frequency;
        this.participationTimePeriod = data.participation_time_period;
        // eslint-disable-next-line max-len
        this.participationProceduresPerformed =
            data.participation_procedures_performed;
        this.notParticipatingTitle = data.not_participating_title;
        this.gapScheme = new GapScheme();
        this.gapScheme.load(data.gap_scheme);
    }
}
