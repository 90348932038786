export default class GapScheme {
    static loadAll(gapSchemes) {
        return gapSchemes.map((gapScheme) => {
            const gs = new GapScheme();
            gs.load(gapScheme);
            return gs;
        });
    }

    constructor() {
        this.id = null;
        this.name = '';
        this.description = '';
        this.disclaimer = '';
        this.participationDescription = '';
        this.graphColour = '';
    }

    load(data) {
        this.id = data.id;
        this.name = data.name;
        this.description = data.description;
        this.disclaimer = data.disclaimer;
        this.participationDescription = data.participation_description;
        this.graphColour = data.graph_colour;
    }
}
