import PropTypes from 'prop-types';
import React from 'react';

import ProfessionalGapScheme from 'professional/models/professional_gap_scheme.js';

export default class ParticipationGraph extends React.Component {
    static propTypes = {
        gapSchemes: PropTypes.arrayOf(
            PropTypes.instanceOf(ProfessionalGapScheme),
        ).isRequired,
        healthFund: PropTypes.object.isRequired,
        profileName: PropTypes.string.isRequired,
        title: PropTypes.string,
    };

    renderGraph(nonParticipatingFrequency, nonParticipatingTitle) {
        const {gapSchemes} = this.props;
        // eslint-disable-next-line max-len
        const nonParticipatingSpanTitle = `${nonParticipatingFrequency}% - ${nonParticipatingTitle}`;
        const nonParticipatingSpanStyle = {
            width: `${nonParticipatingFrequency}%`,
        };
        return (
            <div className="graph">
                {gapSchemes.map((gapScheme) => {
                    const freq = gapScheme.participationFrequency;
                    const name = gapScheme.gapScheme.name;
                    const style = {
                        width: `${freq}%`,
                    };
                    const title = `${freq}% - ${name}`;
                    return (
                        <span
                            className="segment"
                            key={gapScheme.id}
                            style={style}
                            title={title}
                        />
                    );
                })}
                {!!nonParticipatingFrequency && (
                    <span
                        className="segment"
                        style={nonParticipatingSpanStyle}
                        title={nonParticipatingSpanTitle}
                    />
                )}
            </div>
        );
    }

    renderGraphLegend(nonParticipatingFrequency, nonParticipatingTitle) {
        const {gapSchemes} = this.props;

        return (
            <ol className="legend">
                {gapSchemes.map((gapScheme) => (
                    <li
                        key={gapScheme.id}
                        title={gapScheme.gapScheme.description}
                    >
                        <span>
                            <strong>{gapScheme.gapScheme.name}</strong>
                            {' in '}
                            <strong>
                                {`${gapScheme.participationFrequency}%`}
                            </strong>
                            {' of admissions'}
                        </span>
                    </li>
                ))}
                {!!nonParticipatingFrequency && (
                    <li>
                        <span>
                            <strong>{nonParticipatingTitle}</strong>
                            {' in '}
                            <strong>{`${nonParticipatingFrequency}%`}</strong>
                            {' of admissions'}
                        </span>
                    </li>
                )}
            </ol>
        );
    }

    renderParticipationInfo() {
        const {profileName} = this.props;
        const healthFundName = this.props.healthFund.name;
        const firstGapScheme = this.props.gapSchemes[0];
        const procedures = firstGapScheme.participationProceduresPerformed;
        const timePeriod = firstGapScheme.participationTimePeriod;
        if (procedures && timePeriod) {
            return (
                <figcaption>
                    <cite>
                        {`Data based on ${procedures} admission`}
                        {procedures > 1 && 's'}
                        {` in a ${timePeriod} period.`}
                    </cite>
                    <p>
                        {'Percentage of all hospital admissions where '}
                        <strong>{profileName}</strong>
                        {` has participated in a gap scheme applicable to `}
                        {`${healthFundName} members.`}
                    </p>
                </figcaption>
            );
        } else {
            return null;
        }
    }

    render() {
        const {gapSchemes, healthFund, title} = this.props;
        const nonParticipatingFrequency = gapSchemes.reduce(
            (total, gapScheme) => total - gapScheme.participationFrequency,
            100,
        );
        const nonParticipatingTitle =
            healthFund.notParticipatingTitle || 'Not participating';

        return (
            <div className="hs-participation-graph">
                {/* Should title default?  */}
                <h3>{title}</h3>
                {this.renderGraph(
                    nonParticipatingFrequency,
                    nonParticipatingTitle,
                )}
                {this.renderGraphLegend(
                    nonParticipatingFrequency,
                    nonParticipatingTitle,
                )}
                {this.renderParticipationInfo()}
            </div>
        );
    }
}
