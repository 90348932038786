import PropTypes from 'prop-types';
import React from 'react';

import Procedure from 'health_funds/models/procedure.js';

export default class ProcedureGraph extends React.Component {
    static propTypes = {
        procedure: PropTypes.instanceOf(Procedure).isRequired,
    };

    render() {
        const procedure = this.props.procedure;
        const totalAdmissions = procedure.bands.reduce(
            (value, band) => value + band.admissions,
            0,
        );

        return (
            <table>
                <thead>
                    <tr>
                        <th>{'Out-of-pocket cost'}</th>
                        <th>{'Percentage of procedures'}</th>
                    </tr>
                </thead>
                <tbody>
                    {procedure.bands.map((band) => {
                        const percentage = Math.round(
                            (band.admissions / totalAdmissions) * 100,
                        );
                        return (
                            <tr key={band.id}>
                                <td>{`${band.name}`}</td>
                                <td>
                                    <strong>{`${percentage}%`}</strong>
                                    <div>
                                        <span
                                            className="segment"
                                            style={{width: `${percentage}%`}}
                                        />
                                    </div>
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        );
    }
}
